<template>
  <div class="content written-off-order-list">
    <section style="margin-bottom: 1%">
      <a-button type="primary" @click="createHandle">
        <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
        {{ $t('新增核销单') }}
      </a-button>
    </section>
    <div class="form-content">
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <a-input
            allow-clear
            v-model.trim="formData.receivableOrderSn"
            :placeholder="$t('请输入核销单号')"
          />
        </a-col>
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="redo" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <div class="table-content">
      <div class="table-height">
        <VoucherBtn
          :docType="docType"
          :selectedRows="selectedRows"
          :disabled="selectedRows.length === 0"
          @close="searchHandle"
        />
      </div>
      <div class="table-body mt10">
        <a-table
          size="small"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :columns="columns"
          :loading="loading"
          :dataSource="tableData"
          :scroll="{ x: 1500 }"
          rowKey="id"
          :pagination="false"
        >
          <template slot="no" slot-scope="text, record, index">
            <span>{{ index + 1 }}</span>
          </template>
          <template slot="receivableOrderSn" slot-scope="text, record">
            <span class="text-link" @click="jumpSoucePage(record.mainDocSn, record.mainDocId)">
              {{ record.mainDocSn }}
            </span>
          </template>

          <template slot="expandedRowRender" slot-scope="record">
            <CommonTable
              style="background: #fff"
              :dataSource="record.details"
              :columns="detailColumns"
              :showSetting="false"
              :showPagination="false"
              rowKey="id"
            >
              <template v-slot:receiptOrderSn="{ record }">
                <span
                  class="text-link"
                  @click="jumpSoucePage(record.referDocSn, record.referDocId)"
                >
                  {{ record.referDocSn }}
                </span>
              </template>
              <template v-slot:writtenOffAmount="{ text }">
                <PriceInput :showTxt="true" :value="text" />
              </template>
            </CommonTable>
          </template>
        </a-table>
      </div>
      <div class="table-footer mt10">
        <pagination
          :pageNo="pageNo"
          :length="pageSize"
          :total-count="total"
          @paginate="paginate"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import pagination from '@/component/pagination.vue'
import { convertKeysToCamelCase, jumpSoucePage } from '@/common'
export default {
  name: 'WrittenOffOrderList',
  components: { pagination },
  data() {
    return {
      docType: 'ReceivableWrittenOff',
      formData: {},
      tableData: [],
      selectedRows: [],
      selectedRowKeys: [],
      loading: false,
      total: 0,
      pageNo: 1,
      pageSize: 20,
    }
  },
  activated() {
    this.searchHandle()
  },
  computed: {
    ...mapGetters(['writtenOffTypeMapping']),
    columns() {
      return [
        {
          title: '#',
          align: 'center',
          key: 'NO',
          width: 60,
          scopedSlots: {
            customRender: 'no',
          },
        },
        {
          title: this.$t('核销序号'),
          dataIndex: 'writtenOffSn',
          width: 220,
        },
        {
          title: this.$t('核销类型'),
          dataIndex: 'writtenOffType',
          width: 120,
          customRender: (text) => this.writtenOffTypeMapping[text],
        },
        {
          title: this.$t('核销日期'),
          dataIndex: 'writtenOffDate',
        },
        {
          title: this.$t('应收单号'),
          dataIndex: 'receivableOrderSn',
          width: 200,
          scopedSlots: { customRender: 'receivableOrderSn' },
        },
        {
          title: this.$t('结算组织'),
          dataIndex: 'businessUnitName',
        },
        {
          title: this.$t('客户'),
          dataIndex: 'customerName',
        },
        {
          title: this.$t('是否生成凭证'),
          dataIndex: 'voucherGenerated',
          width: 100,
          customRender: (text) => (text ? this.$t('是') : this.$t('否')),
        },
        {
          title: this.$t('凭证号'),
          dataIndex: 'voucherNo',
          width: 100,
        },
        {
          title: this.$t('创建人'),
          dataIndex: 'createdUser',
        },
        {
          title: this.$t('创建时间'),
          dataIndex: 'createdAt',
        },
      ]
    },
    detailColumns() {
      let list = [
        {
          title: this.$t('收款单号'),
          dataIndex: 'receiptOrderSn',
          width: 200,
          scopedSlots: { customRender: 'receiptOrderSn' },
        },
        {
          title: this.$t('核销金额'),
          dataIndex: 'writtenOffAmount',
          width: 220,
          scopedSlots: {
            customRender: 'writtenOffAmount',
          },
        },
      ]
      return list
    },
  },
  methods: {
    jumpSoucePage,
    ...mapMutations(['addPane']),
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.searchHandle(1)
    },
    searchHandle(pageNo) {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.queryList(pageNo)
    },
    createHandle() {
      this.$router.push({ name: 'createWrittenOffOrder' })
    },
    async queryList(pageNo = 0) {
      if (pageNo) {
        this.pageNo = pageNo
      }
      this.loading = true
      await http({
        url: api.getReceivableWrittenOffList,
        data: {
          page_no: this.pageNo,
          page_size: this.pageSize,
          ...this.formData,
        },
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.tableData = result.list
          this.total = res.result.total
        },
      })
      this.loading = false
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    paginate(data) {
      this.pageNo = data.pageNo
      this.pageSize = data.pageSize
      this.queryList()
    },
  },
}
</script>
